'use client';

import { EmptyScreen, Title, Icon, MobileHeader, Button } from '@ui/components';
import { twMerge } from 'tailwind-merge';
import { useGetCreatorsQuery } from '@lib/redux/api/creators-api';
import { useGetUserQuery } from '@lib/redux/api/user-api';
import { MyCreators } from './_components/my-creators';
import { MobileHeaderUser } from '@components/mobile-header-user';
import { Onboarding } from './_components/onboarding/onboarding';
import { NotificationPanel } from '@notification/component';
import { useRouter } from 'next/navigation';
import {
  useGetNotificationsQuery,
  useUpdateNotificationMutation,
  useMarkAllAsReadMutation,
} from '@lib/redux/api/notification-api';
import { ROUTES } from '@constants/routes.constants';
import korcrewLogo from '@ui/assets/images/Logo-text-dark.png';

export default function CreatorsPage() {
  const { data: user, isSuccess, isLoading: isUserLoading } = useGetUserQuery();
  const {
    data: creators,
    isLoading: isCreatorsLoading,
    isSuccess: isCreatorsLoaded,
  } = useGetCreatorsQuery(null, {
    skip: !isSuccess,
  });
  const router = useRouter();

  const handleSettingsRoute = () =>
    router.push(`${ROUTES.settingsMobile}/?tab=notification-preference`);

  const renderMap = {
    empty: () => (
      <div className="flex w-full flex-grow pb-10">
        <EmptyScreen
          title="No one's here yet"
          message="Get the first badge from your favourite creator and they will show up here right away"
          className="h-auto"
        />
      </div>
    ),
    items: () => (
      <div className={twMerge('grid grid-cols-1 items-center justify-items-center')}>
        <MyCreators
          isLoading={isUserLoading || isCreatorsLoading}
          creators={isCreatorsLoaded ? creators : []}
        />
      </div>
    ),
  };

  const state = isCreatorsLoaded && !creators.length ? 'empty' : 'items';

  return (
    <div className="lg:bg-bgGrey flex flex-grow flex-col bg-white lg:bg-gradient-to-b lg:from-white lg:to-[#F4F4F5]">
      <MobileHeader className="border-border border-b">
        <img src={korcrewLogo.src} alt="Korcrew" className="w-[120px]" />
        <div className="flex items-center gap-4">
          <NotificationPanel
            useMarkAllAsReadMutation={useMarkAllAsReadMutation}
            useUpdateNotificationMutation={useUpdateNotificationMutation}
            useGetNotificationsQuery={useGetNotificationsQuery}
            user_id={user?.id}
            onSettings={handleSettingsRoute}>
            {(unread_notifications) => (
              <Button type="icon" className="relative">
                <Icon type="notification" />
                {Boolean(unread_notifications) && (
                  <span className="absolute top-1 right-0 bg-primary rounded-full px-1 text-xs text-white">
                    {unread_notifications}
                  </span>
                )}
              </Button>
            )}
          </NotificationPanel>
          <MobileHeaderUser
            isLoading={isUserLoading}
            avatar={user ? user.avatar : ''}
            username={user ? user.username : ''}
          />
        </div>
      </MobileHeader>
      <div className="flex flex-grow flex-col lg:min-h-screen">
        <div className="justify-between px-8 lg:px-12 xl:px-16 2xl:px-20">
          <Title
            type="xl"
            className="py-4 text-2xl md:py-10 md:text-6xl lg:text-7xl xl:pt-16 2xl:pt-20">
            My KOR Crews
          </Title>
        </div>

        {renderMap[state]()}
      </div>
    </div>
  );
}
