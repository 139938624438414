import Link from 'next/link';
import { Avatar, LoadingSkeleton, Text } from '@ui/components';
import { ROUTES } from '@constants/routes.constants';

interface IMobileHeaderUser {
  username: string;
  avatar: string;
  isLoading?: boolean;
  isSubscribed?: boolean;
}

export function MobileHeaderUser({
  avatar,
  username,
  isLoading,
  isSubscribed = false,
}: IMobileHeaderUser) {
  return isLoading ? (
    <LoadingSkeleton className="h-14 w-14 rounded-full" />
  ) : (
    <div
      className={
        'flex items-center gap-2 overflow-hidden rounded-full p-2 font-medium bg-input hover:bg-inputActive active:bg-inputActive'
      }>
      <Link href={ROUTES.settings}>
        <Avatar
          className={`${!isSubscribed && 'border-0'}`}
          wrapperClassName={`${!isSubscribed && 'p-0'}`}
          src={avatar}
          size="sm"
          isSubscribed={isSubscribed}
        />
      </Link>
    </div>
  );
}
